.footer {
  text-align: center;
  background-color: #333;
  font-weight: 500;
  color: #fff;
}

.footer ion-router-link {
  color: #fff;
}

.footer .flipbook-container {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.footer .local-information {
  color: inherit;
}

.footer .address {
  padding: 5px;
  font-size: 12px;
}

.footer .socials {
  padding: 5px;
}

.footer .socials .social-link {
  padding: 5px;
  color: #fff;
  font-size: 24px;
}

.footer .copyright {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 10px;
}
