body {
  background-color: #fff;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar,
.show-scroll-bar::-webkit-scrollbar {
  width: 0.7em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track,
.show-scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb,
.show-scroll-bar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}