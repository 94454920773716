.header {
  line-height: 0;
}

.header .logo-container {
  display: inline-block;
  padding: 5px;
  height: 90px;
}

.header .logo-container img {
  max-width: 100%;
  max-height: 100%;;
}