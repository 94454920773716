.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.ql-editor {
  min-height: 400px;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-tooltip.ql-editing {
  left: 0 !important;
}

.dark-disabled-input .native-input[disabled].sc-ion-input-md {
  opacity: 1;
}
