.hero-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.hero-container .hero-image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
